var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.access)?_c('div',[_c('NotFound')],1):_c('div',[_vm._m(0),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Dashboard")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{name: 'users'}}},[_c('span',[_vm._v("Users")])])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Create")])])]),_c('br'),_c('div',{staticClass:"container-fluid"},[(_vm.api_error)?_c('div',[_c('b-alert',{attrs:{"show":_vm.alertDismissCountDown,"dismissible":"","variant":"danger"},on:{"dismissed":function($event){_vm.alertDismissCountDown=0},"dismiss-count-down":_vm.alertCountDownChanged}},[_vm._v(_vm._s(_vm.api_error))])],1):_vm._e(),_c('div',{staticClass:"col-md-8"},[_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createUser($event)}}},[_c('div',{staticClass:"form-group row"},[_vm._m(1),_c('div',{staticClass:"col-md-9"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email|max:198"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"emailHelpInline","autocomplete":"off"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),(errors.length != 0)?_c('small',{staticClass:"text-danger field",attrs:{"id":"emailHelpInline"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"form-group row"},[_vm._m(2),_c('div',{staticClass:"col-md-9"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",attrs:{"type":"password","aria-describedby":"passwordHelpInline","autocomplete":"off"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),(errors.length == 0)?_c('small',{staticClass:"text-muted field",attrs:{"id":"passwordHelpInline"}},[_vm._v("Atleast 8 alphanumeric characters")]):_c('small',{staticClass:"text-danger field",attrs:{"id":"passwordHelpInline"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group row"},[_vm._m(3),_c('div',{staticClass:"col-md-9"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.first_name),expression:"user.first_name"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"fnameHelpInline","autocomplete":"off"},domProps:{"value":(_vm.user.first_name)},on:{"change":function($event){return _vm.textTitleCase($event, 'first_name')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "first_name", $event.target.value)}}}),(errors.length == 0)?_c('small',{staticClass:"text-muted field",attrs:{"id":"fnameHelpInline"}},[_vm._v("Minimum 2 characters")]):_c('small',{staticClass:"text-danger field",attrs:{"id":"fnameHelpInline"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-3"},[_vm._v("Last Name")]),_c('div',{staticClass:"col-md-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.last_name),expression:"user.last_name"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"lnameHelpInline","autocomplete":"off"},domProps:{"value":(_vm.user.last_name)},on:{"change":function($event){return _vm.textTitleCase($event, 'last_name')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "last_name", $event.target.value)}}}),_c('small',{staticClass:"text-muted field",attrs:{"id":"lnameHelpInline"}},[_vm._v("(Optional)")])])]),_c('div',{staticClass:"form-group row"},[_vm._m(4),_c('div',{staticClass:"col-md-9"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","options":_vm.roles,"name":"flavour-1","stacked":"","required":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),(errors.length == 0)?_c('small',{staticClass:"text-muted field",attrs:{"id":"rolesHelpInline"}},[_vm._v("Select one or more Roles")]):_c('small',{staticClass:"text-danger field",attrs:{"id":"rolesHelpInline"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)]),_c('div',{staticClass:"form-group row",staticStyle:{"float":"right"}},[_c('div',{staticClass:"offset-xs-3 col-xs-9"},[_c('button',{staticClass:"btn btn-primary text-right ma-10px",attrs:{"type":"button"},on:{"click":function($event){return _vm.createUser()}}},[_vm._v("Submit")])]),_c('div',{staticClass:"offset-xs-3 col-xs-9"},[_c('button',{staticClass:"btn btn-secondary ma-10px",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Cancel")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2 mb-3 border-bottom"},[_c('h2',[_vm._v("Create User")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-md-3"},[_vm._v(" Email "),_c('span',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-md-3"},[_vm._v(" Password "),_c('span',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-md-3"},[_vm._v(" First Name "),_c('span',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-md-3"},[_vm._v(" Roles "),_c('span',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v("*")])])}]

export { render, staticRenderFns }